body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  white-space: pre-line;
  font-size: 1.2rem;
  line-height: 1.87rem;
}

#site-content {
  min-height: calc(100vh - 164px);
}

a {
  text-decoration: none;
  color: white;
  margin: 20px;
  font-size: 1rem;
  font-weight: 600;
}

.section-caption {
	font-size: 30px;
	color: #474747;
	font-weight: medium;
	text-align: center;
}

.custom-underline {
	padding-bottom: 10px;
	border-bottom-style: solid;
	border-bottom-width: 5px;
	border-color: grey;
	display: inline-block;	
}
