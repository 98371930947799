#credentials {
    padding-top: 100px;
    .credentials-content-wrapper {
        text-align: center;

        ul {
            list-style-type: none;
        }
    }

    .service-image .image-caption {
        @media (max-width: 420px) {
            font-size: 4rem;
        }
    }
}