#team {
  padding-top: 100px;
  .team-content-wrapper {
    display: flex;
    justify-content: center;
    div {
      padding: 1rem;
    }
    img {
      width: 500px;
      height: 100%;
    }
    p {
      margin: 0 5px;
      font-style: italic;
      display: inline-block;
    }
    @media (max-width: 420px) {
      p {
        display: block;
      }
    }
  }
}