#contact {
  margin-top: 200px;
  background: #2E4053;
  color: #fff;
  padding: 25px;
  display: flex;
  justify-content: space-between;

  .contact-content {
    span {
      margin-left: 1rem;
    }
    .address {
      margin-bottom: 2rem;
    }
  }

  button {
    color: white;
    border-color: white;
    &:hover {
      background-color: rgba(255,250,250, 0.2);
    }
  }

  .fa-map-marker-alt {
    color: red;
    font-size: 2rem;
  }

  .tel, .whatsapp, .email {
    a {
      font-size: 1.1rem;
    }
  }
}

#website-creator {
  background: #2E4053;
  color: white;
  text-align: center;
  span {
    letter-spacing: -1px;
  }
  a {
    margin: 0;
  }
}