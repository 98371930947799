@import '../../../constants.module.scss';

.service-template {
  .image-caption {
    position: absolute;
    top: 40%;
    left: 150px;
    padding: 0.7rem;
    line-height: 5rem;
    font-weight: bold;
    text-shadow: -3px -3px 0 #000, 3px -3px 0 #000, -3px 3px 0 #000, 3px 3px 0 #000;
  }

  .service-wrapper {
    padding-bottom: 125px;

   .section {
      display: flex;
      margin-bottom: 130px;

      .section-content {
        padding: 40px;
        width: 100%;
      }

      .section-caption {
        // white-space: nowrap;
        margin-top: auto;
        margin-bottom: auto;
        width: 75%;
        line-height: 3.5rem;
        margin-left: 1rem;
      }
    }
    @media (max-width: 768px) {
      .section {
        display: block;
        .section-content {
          width: auto;
        }
        .section-caption {
          display: none;
        }
      }
    }
  }

  table {
    .section-caption {
      width: 500px;
      margin: 25px;  
    }

    hr {
      width: 60%;
      height: 5px;
      background-color: $primarycolor;
      margin-left: 10px;
    }
  }

  @media (max-width: 420px) {
    .image-caption {
      font-size: 2rem;
      line-height: 3rem;
      top: 30%;
    }
  }
  
  .service-image {
    background: no-repeat top center fixed;
    background-size: cover;
    height: 700px;
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 50px;
  
    .image-caption {
      position: absolute;
      margin-top: 136px;
      left: 0;
      padding: 0.7rem;
      line-height: 5rem;
      font-weight: bold;
      font-size: 5rem;
      color: white;
    }  
  }
}
