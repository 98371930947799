#card {
  // margin: 20px;

  .MuiTypography-body2 {
    font-size: 20px !important;
  }

  a {
    color: unset;
    margin: 0;
  }
}