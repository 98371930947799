@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

#about{
	font-family: 'Roboto Slab', serif;
	padding-top: 100px;
	margin: 0 20px 0 20px;

	a {
		text-decoration: none;
		color: -webkit-link;
		margin: 0;
		font-size: 1.2rem;
		font-weight: normal;
	}
}