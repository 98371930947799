#enquiry-modal {
  .small-text {
    font-size: small;
    font-style: italic;
    a {
      color: -webkit-link;
      margin: 0;
      font-size: small;
      font-weight: normal;
    }
  }
}