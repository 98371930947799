@import '../../../constants.module.scss';

#family-law {
  .description {
    padding-left: 3rem;
  }
  @media (max-width: 768px) {
    hr {
      width: 60%;
      height: 5px;
      background-color: $primarycolor;
      margin-left: 10px;
    }
    .section-caption {
      text-align: left;
      padding-left: 2rem;
    }
    .description {
      padding-bottom: 1rem;
    }
  }
}