#home {
	.image-container {
		position: relative;
		color: white;
	}
	
	.header-image {
		background: no-repeat top center fixed;
		background-size: cover;
		height: 700px;
		width: 100%;
		margin: 0;
		padding: 0;
		color: white;
		text-align: left;
		font-size: 50px;
	}

	.header-image-caption-wrapper {
		position: absolute;
	  top: 40%;
	  left: 30px;
	  // transform: translate(-50%, -50%);
		padding: 0.7rem;
		line-height: 5rem;
		font-weight: bold;
		.caption {
			font-size: 3rem;
			margin-bottom: 0;
		}
		.small-caption {
			margin-top: 0;
		}
	}

	.header-image-caption-wrapper {
		text-shadow: -3px -3px 0 #000, 3px -3px 0 #000, -3px 3px 0 #000, 3px 3px 0 #000;
		letter-spacing: 0.2rem; 
	}

	.carousel {
		background-color: saddlebrown;
	}
	
	@media (max-width: 420px) {
		.header-image-caption-wrapper {
			line-height: 3rem;
			top: 30%;
			.caption {
				font-size: 2rem;
				margin-bottom: 0;
			}
			.small-caption {
				margin-top: 0;
			}
		}
	}
}