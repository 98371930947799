#footer{
  // text-align: center;
  // position: absolute;
  .footer-links {
    display: flex;
    justify-content: flex-end;
    margin-right: 53px;
  }

  .footer-text{
    display: table-cell;
    vertical-align: middle;
  }
}

#sm-footer {
    vertical-align: middle;
  .footer-links, .footer-text {
    text-align: center;
  }
}

#footer, #sm-footer{
  display: table;
  width: -webkit-fill-available;
  background-color: #85929E;
  color: #B3B6B7;
  padding: 10px;

  .fa-copyright{
    margin-right: 10px;
  }

  #disclaimer-button a{
    color: white;
    margin: 0px;
    font-size: inherit;
    font-weight: normal;
    &:hover{
      color: black;
    }
  }
  #disclaimer-button:hover {
    background: none;
  }
}
#footer-wrapper {
  
}